export function useStopZoom() {
  // Отключаем масштабирование только при жестах масштабирования (pinch-to-zoom)
  document.addEventListener("gesturestart", function (e: unknown) {
    if (typeof e === "object" && e !== null && "scale" in e) {
      const event = e as { scale: number; preventDefault: () => void };
      event.preventDefault();
    }
  });

  // Отключаем масштабирование при изменении `scale` только, когда scale больше 1 (то есть, начинается зум)
  document.addEventListener(
    "touchmove",
    function (e: unknown) {
      if (typeof e === "object" && e !== null && "scale" in e) {
        const event = e as { scale: number; preventDefault: () => void };
        if (event.scale !== 1) {
          event.preventDefault();
        }
      }
    },
    { passive: false }
  );
}
