<script lang="ts" setup>
import { computed } from "vue";
import { useStore } from "vuex";

import MainSidebarMenu from "./components/MainSidebarMenu/MainSidebarMenu.vue";
import MainSidebarHeader from "./components/MainSidebarHeader/MainSidebarHeader.vue";
import MainSidebarFooter from "./components/MainSidebarFooter/MainSidebarFooter.vue";

import ButtonIcon from "@/components/common/ButtonIcon.vue";

defineOptions({
  name: "MainSidebar",
});

const emit = defineEmits(["logoutAll"]);

const store = useStore();

const isShowSidebar = computed(() => store.state.main.isShowSidebar);
const isCollapsedSidebar = computed(() => store.state.main.isCollapsedSidebar);
const windowInnerWidth = computed(() => store.state.main.windowInnerWidth);
const tabletWidth = computed(() => windowInnerWidth.value < 1280);
const isShowCloseSidebar = computed(
  () => tabletWidth.value && isShowSidebar.value
);

const switchSidebarCloseButton = () => {
  if (isShowCloseSidebar.value) {
    store.commit("main/SET_IS_SHOW_SIDEBAR", false);
  } else {
    store.commit("main/SET_IS_COLLAPSED_SIDEBAR", !isCollapsedSidebar.value);
  }
};
</script>

<template>
  <div
    class="main-sidebar"
    :class="{ 'main-sidebar_collapsed': isCollapsedSidebar }"
  >
    <ButtonIcon
      class="main-sidebar__close sidebar__close light-elevation-shadow-low"
      @click="switchSidebarCloseButton"
    >
      <span
        class="icon-chevron_double_left"
        :class="{ rotate: isCollapsedSidebar }"
      />
    </ButtonIcon>

    <MainSidebarHeader />

    <div class="main-sidebar__body sidebar__body">
      <MainSidebarMenu />
    </div>

    <MainSidebarFooter @logoutAll="emit('logoutAll')" />
  </div>
</template>

<style lang="scss" scoped>
.main-sidebar {
  width: 100%;
  min-width: 60px;
  max-width: 380px;
  height: 100%;
  max-height: 100dvh;
  display: flex;
  flex-direction: column;
  background: var(--color-semantic-background-normal-bg-primary);
  border-right: 1px solid var(--color-semantic-border-normal-soft);
  position: relative;
  z-index: 10;
  @include transition;

  &__body {
    flex: 1 1 100%;
    height: 100%;
    max-height: calc(100dvh - 136px);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__close {
    opacity: 0;
    position: absolute;
    top: 18px;
    left: 364px;
    z-index: 1000006;
    display: flex;
    width: 32px;
    height: 32px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: var(--color-semantic-background-normal-primary);
    @include transition;

    .icon-chevron_double_left {
      width: 16px;
      height: 16px;
      background: var(--color-semantic-content-normal-primary);

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  &_collapsed {
    width: 60px;

    .sidebar__close {
      left: 42px;
    }

    .sidebar__body {
      padding: 8px;
    }
  }

  @media (hover: hover) {
    &:hover {
      .sidebar__close {
        opacity: 1;
      }
    }
  }

  @media (hover: none) {
    .sidebar__close {
      opacity: 1;
    }
  }
}

@media (max-width: 1535px) {
  .main-sidebar {
    max-width: 300px;
  }

  .sidebar__close {
    left: 284px;
  }
}

@media (max-width: 1535px) {
  .sidebar__body {
    padding: 8px;
  }
}

@media (max-width: 1279px) {
  .main-sidebar {
    max-width: 320px;
  }

  .sidebar__close {
    left: 304px;
  }
}

@media (max-width: 1279px) {
  .main-sidebar {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000006;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      display: block;
      width: 100dvw;
      height: 100%;
      background: var(
        --color-semantic-background-normal-overlay,
        rgba(28, 28, 31, 0.5)
      );
      transition: 0.3s ease-in-out;
    }
  }

  .sidebar__close {
    opacity: 1;
  }
}

@media (max-width: 359px) {
  .main-sidebar {
    width: 300px;
  }

  .sidebar__close {
    left: 284px;
  }
}
</style>
