<script setup lang="ts">
import { useStore } from "vuex";
import { ref, computed } from "vue";
import ButtonWrapper from "@/components/common/ButtonWrapper.vue";
import { onClickOutside } from "@vueuse/core";
import { THEME_COLORS } from "../../constants";
import MainToggleSwitch from "@/components/helpers/MainToggleSwitch.vue";
import { IDropMenuItem } from "../../types";
import { IS_PROD_MODE } from "@/boot/api";
import { translateStore } from "@/store/translate";
import { themeColorStore } from "@/store/themeColor";

const emit = defineEmits(["logoutAll"]);

const store = useStore();
const theme = themeColorStore();
const translate = translateStore();

const profile = computed(() => store.state.user.profile);
const isCollapsedSidebar = computed(() => store.state.main.isCollapsedSidebar);

const userName = computed(() => {
  return `${profile.value.first_name} ${profile.value.last_name}`;
});

const themeColor = computed(() => theme.themeColor);

const themeColorChangingButtonText = computed(() =>
  themeColor.value === THEME_COLORS.DARK
    ? "headerButtons.themLight"
    : "headerButtons.themDark"
);

const itemClick = (cb = () => {}) => {
  setTimeout(() => {
    isOpenDropMenu.value = true;
    cb();
  }, 0);
};

const userId = computed(() => store.state.user.profile.id);
const isTestAccount = computed(() => userId.value === 1012);

const isOpenDropMenu = ref<boolean>(false);
const dropMenu = ref<HTMLButtonElement | undefined>(undefined);

const dropMenuList = computed<Array<IDropMenuItem>>(() => [
  {
    icon: themeColor.value === THEME_COLORS.DARK ? "icon-sun" : "icon-moon",
    text: themeColorChangingButtonText.value,
    soon: IS_PROD_MODE ? "sys.soon" : MainToggleSwitch,
    method: () => itemClick(changeTheme),
    disabled: IS_PROD_MODE && !isTestAccount.value,
  },
  {
    icon: "exit",
    text: "headerButtons.exit",
    method: () => itemClick(() => emit("logoutAll")),
  },
]);

onClickOutside(dropMenu, () => {
  isOpenDropMenu.value = false;
});

const showSoonChips = (item: IDropMenuItem) => {
  return item.disabled && typeof item.soon === "string";
};

const soonChipsText = (item: IDropMenuItem) => {
  return typeof item.soon === "string" ? translate.t(item.soon) : "";
};

const changeTheme = () => {
  switch (themeColor.value) {
    case THEME_COLORS.DARK:
      theme.SET_THEME_COLOR(THEME_COLORS.LIGHT);
      break;
    case THEME_COLORS.LIGHT:
      theme.SET_THEME_COLOR(THEME_COLORS.DARK);
      break;

    default:
      theme.SET_THEME_COLOR(THEME_COLORS.DARK);
      break;
  }
};
</script>

<template>
  <div
    class="main-sidebar-footer"
    :class="{ 'main-sidebar-footer_collapsed': isCollapsedSidebar }"
  >
    <div class="main-sidebar-footer__username">
      <p>{{ userName }}</p>
    </div>

    <ButtonWrapper
      ref="dropMenu"
      class="drop-menu"
      :class="{ 'drop-menu_active': isOpenDropMenu }"
      @click="isOpenDropMenu = !isOpenDropMenu"
    >
      <div class="icon-more_vert drop-menu__activator"></div>

      <div
        v-if="isOpenDropMenu"
        class="drop-menu__list light-elevation-shadow-low"
        :class="{ 'drop-menu__list_out': isCollapsedSidebar }"
      >
        <ButtonWrapper
          v-for="item in dropMenuList"
          :key="item.text"
          class="item logout"
          :disabled="item.disabled"
          @click="item.method"
        >
          <span class="icon" :class="{ [item.icon]: true }"></span>
          <p>
            {{ translate.t(item.text) }}
          </p>
          <div v-if="showSoonChips(item)" class="soon">
            <p>
              {{ soonChipsText(item) }}
            </p>
          </div>
          <component
            :is="item.soon"
            v-else
            :model-value="themeColor !== THEME_COLORS.DARK"
            class="switch"
          />
        </ButtonWrapper>
      </div>
    </ButtonWrapper>
  </div>
</template>

<style scoped lang="scss">
.main-sidebar-footer {
  width: 100%;
  height: 68px;
  padding: 14px 12px 14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--color-semantic-border-normal-soft);
  background: var(--color-semantic-background-normal-bg-primary);
  @include transition;

  &__username {
    flex: 1 1 calc(100% - 12px - 36px);
    overflow: hidden;
    margin-right: 8px;

    > p {
      @include body;
      color: var(--color-semantic-content-normal-primary);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .drop-menu {
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    position: relative;
    @include transition;

    &_active,
    &:hover {
      border-radius: 8px;
      background: var(--color-semantic-background-hover-primary, #f7f7f7);
    }

    &__activator {
      display: flex;
      min-width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background: var(--color-semantic-content-normal-primary);
    }

    &__list {
      width: 240px;
      position: absolute;
      bottom: calc(100% + 8px);
      right: 0;
      z-index: 4;
      padding: 4px 0px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      background: var(--color-semantic-background-normal-primary);

      .item {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 12px 16px;
        color: var(--color-semantic-content-normal-primary);

        .icon {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          background: var(--color-semantic-content-normal-primary);
        }

        > p {
          @include body;
          @include body-2;
          color: var(--color-semantic-content-normal-primary);
        }

        .soon {
          display: flex;
          height: 20px;
          padding: 0px 8px 1px 8px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background: var(--color-semantic-background-normal-brand);

          & > p {
            @include caption;
            color: var(--color-semantic-content-normal-static-dark);
          }
        }

        &:hover {
          background: var(--color-semantic-background-normal-secondary);
        }
      }

      &_out {
        left: 0;
      }
    }
  }

  &_collapsed {
    padding: 0;
    justify-content: center;

    .main-sidebar-footer__username {
      flex: 0 0 0;
      opacity: 0;
      margin: 0;
    }
  }

  .switch {
    margin-left: auto;
  }
}

@media (max-width: 1535px) {
  .main-sidebar-footer {
    &__username {
      > p {
        @include body-2;
      }
    }
  }
}
</style>
