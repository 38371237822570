import { TPositions } from "../global-types";

export const RUSSIAN_MONEY_MASKA_OPTIONS = {
  preProcess: (val: string) => val.replace(/[₽,.]/g, ""),
  postProcess: (val: string) => {
    if (!val) {
      return "";
    }

    const formattedValue = new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "RUB",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(parseInt(val || "0"));

    return formattedValue
      .replace(/[₽]/g, "")
      .replace(/(?<=\d)(?=(\d{3})+(?!\d))/g, " ")
      .trim();
  },
};

export const NUMBER_MASK_WITH_SPACES = {
  mask: "########################################################################################################################################################################################################",
  tokens: { "#": { pattern: /\d/, optional: true } },
  options: RUSSIAN_MONEY_MASKA_OPTIONS,
};

export const RUSSIAN_MONEY_MASKA_OPTIONS_FLOAT = {
  preProcess: (val: string) => val.replace(/[' ']/g, "").replace(/[',']/g, "."),
  postProcess: (val: string | number) => {
    if (!val) {
      return "";
    }

    const _val = val.toString();
    const _num = Number(val);

    const sub = 3 - (_val.includes(".") ? _val.length - _val.indexOf(".") : 0);

    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    })
      .format(_num)
      .slice(0, sub ? -sub : undefined)
      .replace("$", "")
      .replaceAll(",", " ")
      .replace(".", ",");
  },
};

export const NUMBER_MASK_WITH_FLOAT = {
  mask: "0.99",
  tokens: "0:\\d:multiple|9:\\d:optional",
  options: RUSSIAN_MONEY_MASKA_OPTIONS_FLOAT,
};

export const POSITION_CLASSES = [
  "POSITION_TOP",
  "POSITION_TOP_END",
  "POSITION_RIGHT_START",
  "POSITION_RIGHT",
  "POSITION_RIGHT_BOTTOM",
  "POSITION_BOTTOM_START",
  "POSITION_BOTTOM",
  "POSITION_BOTTOM_END",
  "POSITION_LEFT_END",
  "POSITION_LEFT",
  "POSITION_LEFT_START",
  "POSITION_TOP_START",
];

export const POSITION_VALUES: Array<TPositions> = [
  "top",
  "top-start",
  "top-end",
  "bottom",
  "bottom-start",
  "bottom-end",
  "right",
  "right-start",
  "right-end",
  "left",
  "left-start",
  "left-end",
];
