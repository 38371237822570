import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import Vuelidate from "vuelidate";
import { i18n } from "@/boot/i18n";
import vClickOutside from "click-outside-vue3";
import { CircleStencil, Cropper, RectangleStencil } from "vue-advanced-cropper";
import { LoadingDirective } from "./package/global-directives/vLoading";

import "vue-advanced-cropper/dist/style.css";
import "@/css/main.scss";
import "@packages/icon-library/style.css";
import "@packages/color-tokens/style.css";
import * as Sentry from "@sentry/vue";

import { createPinia } from "pinia";

const pinia = createPinia();

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://1351a870c581d60ea08b9a87ca595f62@sentry.iilab.ru/59",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== "development",
});

app.directive("disable-validation", {
  mounted(el) {
    if (el.tagName === "FORM") {
      el.setAttribute("novalidate", "");
    }
  },
});

app.mixin({
  mounted() {
    const forms = document.querySelectorAll("form");
    forms.forEach((form) => {
      form.setAttribute("novalidate", "");
    });
  },
});

app.directive("loading", LoadingDirective);

app
  .component("Cropper", Cropper)
  .component("RectangleStencil", RectangleStencil)
  .component("CircleStencil", CircleStencil);

app
  .use(router)
  .use(store)
  .use(Vuelidate)
  .use(i18n)
  .use(vClickOutside)
  .use(pinia);

app.mount("#app");
