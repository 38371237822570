import { createRouter, createWebHistory } from "vue-router";
import ProfileLayout from "@/layouts/ProfileLayout.vue";
import ClearLayout from "@/layouts/ClearLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";

import store from "@/store";
import userStore from "@/store/user";
import mainStore from "@/store/main.store";
import { i18n } from "@/boot/i18n";

const routes = [
  {
    name: "ChangeCountry",
    path: "/change-country",
    component: () => import("@/views/auth/ChangeCountry.vue"),
  },
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        name: "Lending",
        path: "",
        component: () => import("@/views/Index.vue"),
      },
      {
        path: "/contacts",
        component: () => import("@/views/Contacts.vue"),
      },
      {
        path: "login",
        redirect: "/account/login",
      },

      {
        path: "new-password",
        redirect: "/account/new-password",
      },

      {
        path: "email-check",
        redirect: "/account/registration",
      },

      {
        path: "account/email-check",
        redirect: "/account/registration",
      },

      {
        path: "register-code",
        redirect: "/account/registration",
      },
    ],
  },
  {
    path: "/oauth/login",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("@/views/auth/WikiAuth"),
        meta: {
          show_countries: false,
          show_translates: false,
          show_back_button: false,
          is_internal_back_route: false,
          back_button_route: {
            text: "RC Group Wiki",
            name: "",
            route: import.meta.env.VITE_APP_FRONT_RC_WIKI_URL,
          },
        },
      },
    ],
  },
  {
    path: "/account",
    component: AuthLayout,
    children: [
      // авторизация
      {
        path: "",
        redirect: "/account/login",
      },
      {
        name: "Login",
        path: "login",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        name: "ResetPassword",
        path: "reset-password",
        component: () => import("@/views/ResetPassword/ResetPassword.vue"),
      },
      {
        name: "NewPassword",
        path: "new-password",
        component: () => import("@/views/ResetPassword/NewPassword.vue"),
      },
      {
        name: "NewManagerPassword",
        path: "new-manager-password",
        component: () => import("@/views/cabinet/managers/NewManagerPassword"),
      },
      // регистрация
      {
        name: "Registration",
        path: "registration",
        components: {
          default: () => import("@/views/auth/Registration.vue"),
          navigation: () =>
            import("@/components/registration/SidebarNavigation.vue"),
        },
      },
    ],
  },
  // {
  //   path: "/payment-decision",
  //   component: ProfileLayout,
  //   children: [
  //     {
  //       name: "PaymentMethods",
  //       path: "payment-methods",
  //       redirect: { name: "PaymentMethodsConnection" },
  //       component: () =>
  //         import("@/pages/cabinet/payment-methods/index/PaymentMethods.vue"),

  //       children: [
  //         {
  //           name: "PaymentMethodsConnection",
  //           path: "create",
  //           component: () =>
  //             import(
  //               "@/pages/cabinet/payment-methods/index/PaymentMethodsConnection.vue"
  //             ),
  //         },
  //         {
  //           name: "PaymentMethodsHistory",
  //           path: "history",
  //           component: () =>
  //             import(
  //               "@/pages/cabinet/payment-methods/index/PaymentMethodsHistory.vue"
  //             ),
  //         },
  //       ],
  //     },
  //   ],
  //   meta: ["payment_info"],
  // },
  {
    path: "/payment-decision",
    component: ProfileLayout,
    children: [
      {
        name: "PaymentMethods",
        path: "payment-methods",
        redirect: { name: "PaymentMethodsConnection" },
        component: () =>
          import("@/pages/cabinet/payment-methods/index/PaymentMethods.vue"),

        children: [
          {
            name: "PaymentMethodsConnection",
            path: "create",
            component: () =>
              import(
                "@/pages/cabinet/payment-methods/index/PaymentMethodsConnection.vue"
              ),
          },
          {
            name: "PaymentMethodsHistory",
            path: "history",
            component: () =>
              import(
                "@/pages/cabinet/payment-methods/index/PaymentMethodsHistory.vue"
              ),
          },
        ],
      },
    ],
    meta: ["payment_info"],
  },
  {
    path: "/payment-decision",
    component: ProfileLayout,
    children: [
      {
        name: "PaymentMethods",
        path: "payment-methods",
        redirect: { name: "PaymentMethodsConnection" },
        component: () =>
          import("@/pages/cabinet/payment-methods/index/PaymentMethods.vue"),

        children: [
          {
            name: "PaymentMethodsConnection",
            path: "create",
            component: () =>
              import(
                "@/pages/cabinet/payment-methods/index/PaymentMethodsConnection.vue"
              ),
          },
          {
            name: "PaymentMethodsHistory",
            path: "history",
            component: () =>
              import(
                "@/pages/cabinet/payment-methods/index/PaymentMethodsHistory.vue"
              ),
          },
        ],
      },
    ],
    meta: ["payment_info"],
  },
  {
    path: "/cabinet",
    component: ProfileLayout,
    children: [
      {
        path: "",
        redirect: "/",
      },
      {
        name: "Balance",
        path: "balance",
        component: () => import("@/views/cabinet/Balance.vue"),
        meta: {
          permissions: ["balance"],
        },
      },
      {
        name: "Analytics",
        path: "analytics",
        component: () => import("@/views/cabinet/analytics/Analytics.vue"),
        meta: {
          permissions: ["analytics"],
        },
      },
      {
        path: "clients-portrait",
        component: () => import("@/components/analitycs/ClientPortrait.vue"),
        meta: {
          permissions: ["client-portrait"],
        },
      },
      {
        name: "Bally",
        path: "bally",
        component: () => import("@/views/cabinet/loyalty/Cashback.vue"),
        meta: {
          permissions: ["cashback"],
        },
      },
      {
        name: "Promotions",
        path: "promotions",
        component: () => import("@/views/cabinet/loyalty/Promotions"),
        meta: {
          permissions: ["promotion"],
        },
      },
      {
        name: "History",
        path: "history",
        component: () => import("@/views/portfolio/Operations.vue"),
        meta: {
          permissions: ["operation"],
        },
      },
      {
        name: "Clients",
        path: "clients",
        component: () => import("@/views/cabinet/clients/Clients.vue"),
        meta: {
          permissions: ["client"],
        },
      },
      {
        name: "Reviews",
        path: "reviews",
        component: () => import("@/views/cabinet/clients/Reviews.vue"),
        meta: {
          permissions: ["review"],
        },
      },
      {
        name: "Notifications",
        path: "notifications",
        component: () =>
          import("@/views/cabinet/loyalty/PushNotifications.vue"),
        meta: {
          permissions: ["push-notifications"],
        },
      },
      {
        name: "Spread",
        path: "spread",
        component: () => import("@/views/cabinet/spread/Spread.vue"),
        meta: {
          permissions: ["spread"],
        },
      },
      {
        name: "Wallet",
        path: "wallet",
        component: () => import("@/views/cabinet/wallet/Wallet.vue"),
        meta: {
          permissions: ["wallet-pass"],
        },
      },
      {
        path: "managers",
        component: ClearLayout,
        children: [
          {
            path: "",
            name: "Managers",
            component: () => import("@/views/cabinet/managers/Managers.vue"),
          },
          {
            path: "add",
            name: "ManagersAdd",
            component: () =>
              import("@/views/cabinet/managers/AddNewManager.vue"),
          },
          {
            path: ":id/change",
            name: "ManagersChange",
            component: () =>
              import("@/views/cabinet/managers/ChangeManager.vue"),
            props: true,
          },
        ],
        meta: {
          permissions: ["manager"],
        },
      },
      {
        path: "employee",
        component: ClearLayout,
        children: [
          {
            path: "",
            name: "Employee",
            component: () => import("@/views/cabinet/employee/Employees.vue"),
          },
          {
            path: "score/:id",
            name: "EmployeeAbout",
            component: () => import("@/views/cabinet/employee/EmployeeScore"),
            props: true,
          },
        ],
        meta: {
          permissions: ["employee"],
        },
      },
      {
        name: "Points",
        path: "points",
        component: () => import("@/views/cabinet/Points.vue"),
        meta: {
          permissions: ["points"],
        },
      },
      {
        path: "entities",
        component: ClearLayout,
        children: [
          {
            path: "",
            name: "Entities",
            component: () => import("@/views/cabinet/Entities.vue"),
          },
        ],
        meta: {
          permissions: ["legal-entity"],
        },
      },
      {
        path: "trading-point",
        component: ClearLayout,
        children: [
          {
            path: "",
            name: "TradingPoint",
            component: () =>
              import("@/views/cabinet/trading-points/TradingPoints"),
          },
          {
            path: "create",
            name: "TradingPointsCreate",
            component: () =>
              import("@/views/cabinet/trading-points/TradingPointsCreate"),
          },
          {
            path: ":id",
            component: ClearLayout,
            children: [
              {
                path: "",
                name: "TradingPointsEdit",
                component: () =>
                  import(
                    "@/views/cabinet/trading-points/TradingPointsEdit.vue"
                  ),
                props: true,
              },
              {
                path: "history",
                name: "TradingPointHistory",
                component: () =>
                  import(
                    "@/views/cabinet/trading-points/TradingPointsChecksHistory.vue"
                  ),
                props: true,
              },
            ],
          },
        ],
        meta: {
          permissions: ["trading-point"],
        },
      },
      {
        name: "Documents",
        path: "documents",
        component: () => import("@/views/cabinet/Documents.vue"),
        meta: {
          permissions: ["document"],
        },
      },
      {
        path: "acts",
        component: ClearLayout,
        children: [
          {
            path: "",
            name: "Acts",
            component: () => import("@/views/portfolio/ActsAndAccounts.vue"),
          },
        ],
        meta: {
          permissions: ["act-and-receipt"],
        },
      },
      {
        name: "Tariff",
        path: "tariff",
        component: () => import("@/views/cabinet/Tariffs.vue"),
        meta: {
          permissions: ["tariff"],
        },
      },
      {
        name: "PromotionServices",
        path: "promotion-services",
        component: () =>
          import("@/views/cabinet/promotion-services/PromotionServices.vue"),
        redirect: { name: "ServicesList" },

        children: [
          {
            name: "ServicesList",
            path: "catalog",
            component: () =>
              import("@/views/cabinet/promotion-services/ServicesList.vue"),
            props: (route) => ({ query: route.query.q }),
          },
          {
            name: "OrdersHistory",
            path: "orders-history",
            component: () =>
              import("@/views/cabinet/promotion-services/OrdersHistory.vue"),
          },
        ],
      },
      {
        name: "PreOrders",
        path: "pre-orders",
        component: () => import("@/views/cabinet/PreOrders.vue"),
      },
      {
        name: "Counterparties",
        path: "counterparties",
        component: () => import("@/views/cabinet/Counterparties.vue"),
        meta: {
          permissions: ["check-counterparties"],
        },
      },
      {
        name: "Emenu",
        path: "e-menu",
        component: () => import("@/views/cabinet/EMenu.vue"),
        children: [
          {
            path: "/",
            redirect: "products",
          },
          {
            name: "Products",
            path: "products",
            component: () => import("@/views/cabinet/EMenu.vue"),
          },
          {
            name: "Categories",
            path: "categories",
            component: () => import("@/views/cabinet/EMenu.vue"),
          },
        ],
        meta: {
          permissions: ["document"],
        },
      },
      {
        name: "GiftCard",
        path: "gift-card",
        component: () => import("@/views/cabinet/gift-card/GiftCard.vue"),
        meta: {
          permissions: ["gift-card"],
        },
        redirect: { name: "GiftInformation" },
        children: [
          {
            name: "GiftInformation",
            path: "information",
            component: () =>
              import("@/views/cabinet/gift-card/GiftInformation.vue"),
          },
          {
            name: "GiftIssuedCards",
            path: "issued-cards",
            component: () =>
              import("@/views/cabinet/gift-card/GiftIssuedCards.vue"),
          },
        ],
      },
      {
        path: "terminals-MSPOS",
        component: ClearLayout,
        children: [
          {
            path: "",
            name: "MSPOS",
            component: () =>
              import("@/views/cabinet/clients/TerminalsMSPOS.vue"),
          },
          {
            path: "buy-terminal",
            name: "TerminalBuy",
            component: () => import("@/views/cabinet/clients/BuyTerminal.vue"),
          },
          {
            path: "order-terminal",
            name: "TerminalOrder",
            component: () =>
              import("@/views/cabinet/clients/OrderTerminal.vue"),
            params: [],
          },
          {
            path: "about-order-terminal",
            name: "AboutTerminalOrder",
            component: () =>
              import("@/views/cabinet/clients/AboutTerminalOrder.vue"),
          },
        ],
        meta: {
          permissions: ["terminals"],
        },
      },
      {
        path: "MPOS",
        component: () => import("@/views/cabinet/clients/TerminalsLIMON.vue"),
        name: "LIMON",
        meta: {
          permissions: ["terminals"],
        },
      },
      {
        name: "FAQ",
        path: "FAQ",
        component: () => import("@/views/cabinet/FAQ.vue"),
      },
      {
        path: "store",
        redirect: "trading-point",
      },
      {
        path: "organizations",
        redirect: "cabinet/entities",
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("@/views/NotFoundPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  if (mainStore.state.windowInnerWidth < 1280) {
    setTimeout(() => {
      store.commit("main/SET_IS_SHOW_SIDEBAR", false);
    }, 300);
  }

  if (to.meta.auth) {
    localStorage.setItem("isCollapsedSidebar", false);
    if (!localStorage.getItem("access_token")) {
      return next();
    } else {
      if (userStore.state.permissions.length === 0) {
        await store.dispatch("user/getProfileInformation");
      }

      const firstPermission = userStore.state.permissions.permissions[0];
      const routes = {
        balance: "/cabinet/balance",
        analytics: "/cabinet/analytics",
        "legal-entity": "/cabinet/entities",
        "trading-point": "/cabinet/store",
        employee: "/cabinet/employee",
        document: "/cabinet/documents",
        business: "/cabinet/business",
        tariff: "/cabinet/tariff",
        operation: "/cabinet/history",
        "act-and-receipt": "/cabinet/acts",
        manager: "/cabinet/managers",
        cashback: "/cabinet/bally",
        spread: "/cabinet/spread",
        client: "/cabinet/clients",
        "wallet-pass": "/cabinet/wallet",
        review: "/cabinet/reviews",
        "trading-point-promotion": "/cabinet/promotion-services",
        orders: "/cabinet/pre-orders",
      };

      const nextRoute = routes[firstPermission] || "/cabinet/faq";
      return next(nextRoute);
    }
  }

  if (!to.meta.permissions) {
    return next();
  }

  if (userStore.state.permissions.length === 0) {
    await store.dispatch("user/getProfileInformation");
  }

  const owner = userStore.state.profile.is_owner;
  const userPermissions = userStore.state.permissions;

  if (owner) {
    return next();
  } else {
    const hasPermission = to.meta.permissions.some((item) =>
      userPermissions.includes(item)
    );

    if (!hasPermission) {
      const descr = "noPermissions";
      const mes = !Object.keys(i18n.global.tm("sys")).includes(descr)
        ? descr
        : i18n.global.t(`sys.${descr}`);

      store.dispatch("createNotification", {
        type: "error",
        status: i18n.global.t("sys.error"),
        message: mes,
      });
    }

    return next(hasPermission ? undefined : from.path);
  }
});

export default router;
