import { POSITION_VALUES } from "../global-constants";
import { TPositions } from "../global-types";

export interface ISetDropPositionOption {
  activatorPosition: undefined | DOMRect;
  dropPosition: undefined | DOMRect;
  ignorePosition: boolean;
  side: TPositions;
  preferredPositions?: TPositions[];
  windowSize: { width: number; height: number };
  zIndex: number;
  space: number;
  preferredPositionsOnly?: TPositions;
}

const HELPER_SIZE = 16;

interface IPositionsListItem {
  top: string;
  left: string;
  positionClass: string;
}
interface IPositionsList {
  [index: string]: () => IPositionsListItem;
}

const setPosition = (options: ISetDropPositionOption) => {
  const {
    activatorPosition,
    dropPosition,
    ignorePosition,
    side,
    preferredPositions,
    windowSize,
    zIndex = 1000000,
    space = HELPER_SIZE,
    preferredPositionsOnly = undefined,
  } = options;

  const TA = activatorPosition?.top || 0;
  const LA = activatorPosition?.left || 0;
  const RA = activatorPosition?.right || 0;
  const BA = activatorPosition?.bottom || 0;
  const WA = activatorPosition?.width || 0;
  const HA = activatorPosition?.height || 0;
  const WC = dropPosition?.width || 0;
  const HC = dropPosition?.height || 0;

  const setValue = (value: number) => (value > 0 ? `${value}px` : "initial");

  const positions: IPositionsList = {
    top: () => ({
      top: setValue(TA - HC - space),
      left: setValue(RA - (WC / 2 + WA / 2)),
      positionClass: "POSITION_TOP",
    }),
    "top-end": () => ({
      top: setValue(TA - HC - space),
      left: setValue(LA - space / 2),
      positionClass: "POSITION_TOP_END",
    }),
    "right-start": () => ({
      top: setValue(TA - HC / 2),
      left: setValue(RA + space),
      positionClass: "POSITION_RIGHT_START",
    }),
    right: () => ({
      top: setValue(BA - HC / 2 - HA / 2),
      left: setValue(RA + space),
      positionClass: "POSITION_RIGHT",
    }),
    "right-end": () => ({
      top: setValue(BA - space - HA / 2),
      left: setValue(RA + space),
      positionClass: "POSITION_RIGHT_BOTTOM",
    }),
    "bottom-start": () => ({
      top: setValue(BA + space),
      left: setValue(RA - WC + space / 2),
      positionClass: "POSITION_BOTTOM_START",
    }),
    bottom: () => ({
      top: setValue(BA + space),
      left: setValue(RA - (WC / 2 + WA / 2)),
      positionClass: "POSITION_BOTTOM",
    }),
    "bottom-end": () => ({
      top: setValue(BA + space),
      left: setValue(LA - space / 2),
      positionClass: "POSITION_BOTTOM_END",
    }),
    "left-end": () => ({
      top: setValue(BA - space - HA / 2),
      left: setValue(LA - WC - space),
      positionClass: "POSITION_LEFT_END",
    }),
    left: () => ({
      top: setValue(BA - HC / 2 - HA / 2),
      left: setValue(LA - WC - space),
      positionClass: "POSITION_LEFT",
    }),
    "left-start": () => ({
      top: setValue(TA - HC / 2),
      left: setValue(LA - WC - space),
      positionClass: "POSITION_LEFT_START",
    }),
    "top-start": () => ({
      top: setValue(TA - HC - space),
      left: setValue(RA - WC + space / 2),
      positionClass: "POSITION_TOP_START",
    }),
  };

  const checkCanSet = (position: string): boolean => {
    const posFn = positions[position];
    const { top, left } = posFn();

    return (
      top !== "initial" &&
      left !== "initial" &&
      parseInt(top.replace("px", "")) + HC < windowSize.height &&
      parseInt(left.replace("px", "")) + WC < windowSize.width
    );
  };

  const setPositionByDefault = () => {
    return POSITION_VALUES;
  };

  let positionClass = side;

  // Сначала проверяем указанную позицию
  if (!checkCanSet(positionClass)) {
    // Если элемент не помещается в указанную позицию, проверяем предпочтительные позиции
    if (preferredPositions && preferredPositions.length > 0) {
      const preferredPosition = preferredPositions.find((pos) =>
        checkCanSet(pos)
      );
      if (preferredPosition) {
        positionClass = preferredPosition;
      }
    }

    // Если ни одна из предпочтительных позиций не подходит, пытаемся найти любую подходящую позицию
    if (!checkCanSet(positionClass)) {
      if (preferredPositionsOnly) {
        positionClass = preferredPositionsOnly;
      } else {
        const order = setPositionByDefault();
        for (let i = 0; i < order.length; i++) {
          if (checkCanSet(order[i])) {
            positionClass = order[i];
            break;
          }
        }
      }
    }
  }

  const position = positions[positionClass]();

  if (ignorePosition) {
    position.positionClass = "POSITION_BOTTOM";
  }

  return { ...position, zIndex };
};

export const setDropPosition = (options: ISetDropPositionOption) => {
  return setPosition(options);
};
