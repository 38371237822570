<script>
import NavSwitch from "@/components/index/NavSwitch.vue";
import { LANGUAGES } from "@/package/variables/countriesAndLanguages";
import { mapMutations, mapState } from "vuex";
import rcLogoFullLight from "@/assets/icons/rc-logo-full.svg";
import rcLogoFullDark from "@/assets/icons/rc-logo-full-dark.svg";

export default {
  name: "AuthLayout",

  components: { NavSwitch },

  data() {
    return {
      lang: "",
      langOptions: LANGUAGES,
      isOpenBackNotify: true,
      theme: null,
    };
  },

  watch: {
    lang(lang) {
      this.$i18n.locale = lang;
      if (lang !== localStorage.getItem("selected-language")) {
        localStorage.setItem("selected-language", lang);
        window.location.reload();
      }
    },

    notification: {
      handler(newValue) {
        this.isOpenBackNotify = !!newValue;
      },
    },
  },

  computed: {
    ...mapState({
      notification: (state) => state.notification,
    }),

    logoSrc() {
      return this.theme === "light" ? rcLogoFullDark : rcLogoFullLight;
    },

    isInternalBackRoute() {
      return this.$route?.meta?.is_internal_back_route ?? true;
    },

    backButtonRoute() {
      if (this.$route.meta?.back_button_route) {
        return this.$route.meta.back_button_route;
      }

      const specialRoutes = ["Login", "NewPassword", "NewManagerPassword"];

      if (specialRoutes.includes(this.$route.name)) {
        return {
          text: this.$t("navbar.page1"),
          name: "Lending",
        };
      }

      return {
        text: this.$t("headerButtons.exit"),
        name: "Lending",
      };
    },
  },

  methods: {
    ...mapMutations(["SET_NOTIFICATION"]),

    setLanguage(option) {
      this.lang = option;
    },

    tokenClearing() {
      if (this.$route.name === "Login") {
        return;
      }

      localStorage.removeItem("access_token");
      localStorage.removeItem("emailCode");
      localStorage.removeItem("email");
    },

    clearNotification() {
      this.isOpenBackNotify = false;
      this.SET_NOTIFICATION(null);
    },

    getThemeFromLocalStorage() {
      return localStorage.getItem("themeColor") || "dark";
    },
  },

  mounted() {
    this.theme = this.getThemeFromLocalStorage();
    document.documentElement.dataset.theme = this.theme;

    this.lang = "ru";
    let localLang = localStorage.getItem("selected-language");

    if (localLang === "ru-ru") {
      localLang = "ru";
      localStorage.setItem("selected-language", "ru");
    }

    if (localLang) {
      this.lang = localLang;
    } else {
      localStorage.setItem("selected-language", "ru");
      this.lang = "ru";
    }
  },
};
</script>

<template>
  <section class="auth-layout">
    <div class="auth-layout__sidebar sidebar">
      <img class="sidebar__logo" :src="logoSrc" alt="logo" />

      <RouterView name="navigation" />
    </div>

    <div id="form-container" class="form-container">
      <div class="form-container__header">
        <template v-if="isInternalBackRoute">
          <RouterLink
            class="form-container__back-button"
            :to="{ name: backButtonRoute.name }"
            @click="tokenClearing"
          >
            <span class="icon-back-arrow" />
            <p>{{ backButtonRoute.text }}</p>
          </RouterLink>
        </template>

        <template v-else>
          <a
            class="form-container__back-button"
            :href="backButtonRoute.route"
            @click="tokenClearing"
          >
            <span class="icon-back-arrow" />
            <p>{{ backButtonRoute.text }}</p>
          </a>
        </template>
      </div>

      <div class="form-container__page">
        <RouterView />
      </div>

      <div class="form-container__footer">
        <NavSwitch
          v-model="lang"
          :options="langOptions"
          list-position="top-left"
          without-mobile-style
          @select="setLanguage"
        />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.auth-layout {
  display: grid;
  grid-template-columns: 320px 1fr;
  height: 100vh;
}

.sidebar {
  background: var(--color-semantic-background-normal-bg-secondary);
  padding: 0 40px;

  &__logo {
    width: 112px;
    height: 40px;
    margin: 16px 0;
  }
}

.form-container {
  height: 100%;
  display: grid;
  grid-template-rows: 72px 1fr 48px;
  overflow: auto;
  @include scrollbar;

  &__header {
    height: fit-content;
    padding: 40px 40px 0 40px;

    @media (max-width: 767px) {
      padding: 36px 24px;
    }

    @media (max-width: 411px) {
      padding: 36px 16px;
    }
  }

  &__back-button {
    color: var(--color-semantic-content-normal-link);
    padding: 0;
    display: flex;
    gap: 8px;
    @include body;
    text-decoration: none;
    cursor: pointer;

    p {
      cursor: pointer;
    }

    .icon-back-arrow {
      width: 24px;
      height: 24px;
      background: var(--color-semantic-content-normal-link);
    }
  }

  &__page {
    @media (max-width: 1365px) {
      display: flex;
      align-items: center;
    }

    @media (max-width: 767px) {
      display: block;
    }
  }

  &__footer {
    padding: 0 40px;
    display: flex;
    align-items: flex-start;
  }
}

.icon-hide-thin,
.icon-show-thin {
  width: 20px;
  height: 20px;
  background: var(--color-semantic-content-normal-primary);
}

@media (max-width: 1279px) {
  .auth-layout {
    grid-template-columns: 1fr;
  }

  .sidebar {
    display: none;
  }
}
</style>
