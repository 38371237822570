<script setup lang="ts">
import { PropType, Ref, computed, ref, nextTick } from "vue";
import { useRoute, RouterLink } from "vue-router";
import { onClickOutside, useWindowSize } from "@vueuse/core";
import { debounce } from "lodash";
import type { TMenuItem, TMenuItemDetail } from "../../types";
import calculationPosition from "@/workers/calculationPosition?worker";
import { POSITION_CLASSES, POSITION_VALUES } from "@/package/global-constants";
import {
  ISetDropPositionOption,
  setDropPosition,
} from "@/package/global-composables/setDropPosition";
import TransitionExpand from "@/components/TransitionExpand.vue";
import TransitionOpacity from "@/ui/TransitionOpacity.vue";
import ButtonWrapper from "@/components/common/ButtonWrapper.vue";

let calculationPositionWorker = null;
if (window.Worker) {
  calculationPositionWorker = new calculationPosition();
}

defineOptions({
  name: "MainSidebarMenuItem",
});

const props = defineProps({
  item: {
    type: Object as PropType<TMenuItem>,
    default: () => {},
  },
  collapse: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["collapse"]);
const route = useRoute();

const windowSize = useWindowSize();

const collapseMenu = () => {
  if (props.item.details.length > 0 && !props.collapse) {
    emit("collapse", props.item.visible ? "" : props.item.name);
  }
};

const isShowBody = computed(() => {
  return (
    props.item.details.length > 0 && (props.item.visible || isOpened.value)
  );
});

const headerComponent = computed(() => {
  return props.item.details.length > 0 ? ButtonWrapper : RouterLink;
});

const transitionComponent = computed(() => {
  return props.collapse ? TransitionOpacity : TransitionExpand;
});

const activeClass = (element: TMenuItem | TMenuItemDetail) => {
  let activeDetails = false;

  if (element?.details) {
    for (const key in element?.details || []) {
      activeDetails = route.fullPath
        .split("/")[2]
        .includes(element.details[key].path);
      if (activeDetails) {
        break;
      }
    }

    return element.path
      ? route.fullPath.split("/")[2].includes(element.path)
      : activeDetails;
  }

  return route.fullPath.split("/")[2].includes(element.path);
};

const isOpened = ref(false);
const menuItem: Ref<HTMLDivElement | null> = ref(null);
const activator: Ref<HTMLDivElement | null> = ref(null);
const content: Ref<HTMLDivElement | null> = ref(null);
const setStyleValue = ref({
  positionClass: "",
});

const toggleMenu = debounce((open: boolean) => {
  if (props.item.details.length > 0 && props.collapse) {
    isOpened.value = open;

    if (isOpened.value) {
      nextTick(startCalculatePositions);
    }
  }
}, 300);

const startCalculatePositions = () => {
  const options: ISetDropPositionOption = {
    activatorPosition: activator.value?.getBoundingClientRect(),
    dropPosition: content.value?.getBoundingClientRect(),
    ignorePosition: false,
    side: "right",
    windowSize: {
      width: windowSize.width.value,
      height: windowSize.height.value,
    },
    preferredPositions: POSITION_VALUES,
    zIndex: 1,
    space: 16,
  };

  if (window.Worker && calculationPositionWorker) {
    calculationPositionWorker.postMessage(JSON.stringify(options));
    calculationPositionWorker.onmessage = (e) => {
      const result = JSON.parse(e.data);

      setStyleValue.value = result;
      if (setStyleValue.value && content.value) {
        content.value.classList.remove(...POSITION_CLASSES);
        content.value.classList.add(
          setStyleValue.value.positionClass,
          "light-elevation-shadow-low"
        );
      }
    };
  } else {
    setStyleValue.value = setDropPosition(options);
    if (setStyleValue.value && content.value) {
      content.value.classList.remove(...POSITION_CLASSES);
      content.value.classList.add(
        setStyleValue.value.positionClass,
        "light-elevation-shadow-low"
      );
    }
  }
};

const closeAfterSelect = () => {
  if (props.collapse) {
    emit("collapse", "");
    toggleMenu(false);
  }
};

onClickOutside(
  menuItem,
  () => {
    closeAfterSelect();
  },
  { ignore: [activator, content] }
);
</script>

<template>
  <div
    ref="menuItem"
    class="main-sidebar-menu__item menu_item"
    :class="{ collapse }"
    @mouseenter="toggleMenu(true)"
    @mouseleave="toggleMenu(false)"
  >
    <component
      :is="headerComponent"
      class="menu_item__header header"
      :class="{
        active: activeClass(item),
        open: isShowBody,
        collapse,
      }"
      :to="{ name: item.componentName || null }"
      @click="collapseMenu"
    >
      <div ref="activator" class="header__icon-wrapper icon-wrapper">
        <span
          v-if="props.item.icon"
          :class="props.item.icon"
          class="header__icon icon"
        />
      </div>

      <div v-if="!collapse" class="header__title title">
        <span>{{ $t(`leftPanel.${props.item.name}`) }}</span>
      </div>

      <div
        v-if="props.item.details.length && !collapse"
        class="header__arrow arrow icon-arrow-down"
        :class="{ rotate: props.item.visible, open: isShowBody }"
      />
    </component>

    <Teleport to="body" :disabled="!collapse">
      <component :is="transitionComponent">
        <div
          v-if="isShowBody"
          ref="content"
          class="menu_item__body body"
          :class="{ collapse }"
          :style="setStyleValue"
          @mouseenter="toggleMenu(true)"
          @mouseleave="toggleMenu(false)"
        >
          <RouterLink
            v-for="detail in props.item.details"
            :key="detail.name"
            class="body__item item"
            :class="{ active: activeClass(detail) }"
            :to="{ name: detail.componentName }"
            @click="closeAfterSelect"
          >
            <div class="item__title title">
              <span>{{ $t(`leftPanel.${detail.name}`) }}</span>
            </div>
          </RouterLink>
        </div>
      </component>
    </Teleport>
  </div>
</template>

<style scoped lang="scss">
.menu_item {
  width: 100%;
  position: relative;
  cursor: pointer;
  color: var(--color-semantic-content-hover-primary);
  @include transition;

  .icon {
    width: 32px;
    height: 32px;
    background: var(--color-semantic-content-normal-tertiary);
    flex-shrink: 0;

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 32px;
    }
  }

  .header {
    .title {
      margin: 0;
    }
  }

  &.collapse {
    .header {
      padding: 10px;
      justify-content: center;

      .title,
      .arrow {
        width: 0;
      }

      &::before {
        left: -8px;
      }
    }
  }

  .title {
    flex: 1 1 100%;
    margin: 0;
    @include transition;

    > span {
      @include body;
      color: var(--color-semantic-content-normal-primary);
    }
  }

  .arrow {
    width: 16px;
    height: 16px;
    background: var(--color-semantic-content-normal-tertiary);
    flex-shrink: 0;
    @include transition;

    &.open {
      transform: rotate(180deg);
    }
  }

  .item::before,
  .header::before {
    content: "";
    display: flex;
    width: 4px;
    height: 40px;
    align-items: flex-start;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    background-color: var(--color-semantic-content-normal-brand);
    border-radius: 0 4px 4px 0;
    opacity: 0;
  }

  .item,
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 12px 16px;
    border-radius: 8px;
    position: relative;
    @include transition;
  }

  .header {
    display: flex;

    .title {
      overflow: hidden;

      > span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &.active,
    &.router-link-exact-active {
      &.collapse {
        background: var(--color-semantic-background-normal-secondary);

        &::before {
          opacity: 1;
        }
      }

      &:not(.open) {
        background: var(--color-semantic-background-normal-secondary);

        .title {
          > span {
            @include body-bold;
            color: var(--color-semantic-content-normal-primary);
          }
        }

        &::before {
          opacity: 1;
        }
      }
    }

    &.collapse {
      gap: 0;

      &.open,
      &:hover {
        background: var(--color-semantic-background-normal-secondary);
      }
    }
  }
}

.body {
  position: static;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 4px;
  min-width: 216px;

  &.collapse {
    position: absolute;
    width: max-content;
    max-width: 260px;
    border-radius: 8px;
    border: 1px solid var(--color-semantic-border-normal-soft);
    background: var(--color-semantic-background-normal-primary);
    padding: 4px 0;
    margin-top: 0;

    .item {
      padding-left: 16px;
    }
  }

  .item {
    padding: 16px 16px 16px 64px;
    color: var(--color-semantic-content-hover-primary);

    &.active,
    &.router-link-exact-active {
      background: var(--color-semantic-background-normal-secondary);

      .title {
        > span {
          @include body-bold;
          color: var(--color-semantic-content-normal-primary);
        }
      }

      &::before {
        opacity: 1;
      }
    }

    &:hover {
      .title {
        overflow: hidden;

        > span {
          color: var(--color-semantic-content-hover-primary);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@media (max-width: 1535px) {
  .menu_item {
    .icon {
      width: 24px;
      height: 24px;
    }

    .item,
    .header {
      padding: 12px;
      gap: 8px;

      .title {
        overflow: hidden;
        > span {
          @include body-2;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .header {
      &.active {
        &:not(.open) {
          .title {
            > span {
              @include body-2-bold;
            }
          }
        }
      }
    }

    .item {
      padding: 14px 12px 14px 44px;

      &.active {
        .title {
          > span {
            @include body-2-bold;
          }
        }
      }
    }
  }
}
</style>
