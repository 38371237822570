<script setup lang="ts">
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { onClickOutside } from "@vueuse/core";

import { THEME_COLORS } from "../../constants";

import pos from "@/assets/icons/pos-logo.svg";
import posDark from "@/assets/icons/POSlogoDark.svg";
import rc_logo from "@/assets/icons/rc-logo.svg";
import rc_logo_dark from "@/assets/icons/logoDark.svg";
import rcLogoFullLight from "@/assets/icons/rc-logo-full.svg";
import rcLogoFullDark from "@/assets/icons/rc-logo-full-dark.svg";

import ButtonWrapper from "@/components/common/ButtonWrapper.vue";
import { IS_PROD_MODE } from "@/boot/api";
import { themeColorStore } from "@/store/themeColor";

defineOptions({
  name: "MainSidebarHeader",
});

const serviceSelector = ref();

const store = useStore();
const theme = themeColorStore();

const profile = computed(() => store.state.user.profile);
const isCollapsedSidebar = computed(() => store.state.main.isCollapsedSidebar);
const systemCountry = computed(() => store.state.system.system_country);
const rcLogo = computed(() =>
  theme.themeColor === THEME_COLORS.DARK ? rc_logo : rc_logo_dark
);
const posLogo = computed(() =>
  theme.themeColor === THEME_COLORS.DARK ? pos : posDark
);

const isShowImage = computed(() => {
  return (
    isCollapsedSidebar.value ||
    !profile.value.has_pos ||
    systemCountry.value !== "ru"
  );
});

const isOpenList = computed(() => {
  return isOpen.value && !isCollapsedSidebar.value;
});

const rcLogoFull = computed(() =>
  theme.themeColor === THEME_COLORS.DARK ? rcLogoFullLight : rcLogoFullDark
);

const showImage = computed(() => {
  return isCollapsedSidebar.value ? rcLogo.value : rcLogoFull.value;
});

const link = computed(() => {
  if (!IS_PROD_MODE) {
    return "https://rc-pos-partner-frontend-staging.stage.rccore.net/login";
  } else {
    return "https://tsp.rcpos.app/login";
  }
});

const isOpen = ref<boolean>(false);
onClickOutside(serviceSelector, () => {
  isOpen.value = false;
});
</script>

<template>
  <div
    ref="serviceSelector"
    class="main-sidebar-header"
    :class="{
      'main-sidebar-header_collapsed': isCollapsedSidebar,
    }"
  >
    <img
      v-if="isShowImage"
      class="sidebar-header__logo logo"
      :class="{
        logo_collapsed: isCollapsedSidebar,
      }"
      :src="showImage"
      alt="logo"
    />

    <ButtonWrapper
      v-else
      class="sidebar-header__button button"
      :class="{ button_active: isOpen }"
      @click="isOpen = !isOpen"
    >
      <img :src="rcLogo" alt="rc logo" class="button__logo" />

      <div class="button__info">
        <h5>RC CITY</h5>
        <p>Переключить сервис</p>
      </div>

      <div class="button__arrow">
        <span
          class="icon-arrow-down"
          :class="{
            'icon-arrow-down_rotate': isOpen,
          }"
        />
      </div>
    </ButtonWrapper>

    <div
      v-if="isOpenList"
      class="sidebar-header__list list light-elevation-shadow-low"
    >
      <a :href="link" class="list__item">
        <img :src="posLogo" alt="pos logo" />

        <p>RC POS</p>
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.main-sidebar-header {
  width: 100%;
  height: 68px;
  flex: 0 0 68px;
  padding: 6px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  position: relative;

  .logo {
    width: 134px;
    height: 40px;

    &_collapsed {
      width: 40px;
    }
  }

  .button {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 16px 8px 12px;
    gap: 12px;
    border-radius: 8px;
    cursor: pointer;

    &__logo {
      width: 40px;
      height: 40px;
      cursor: pointer;
      flex-shrink: 0;
    }

    &__arrow {
      cursor: pointer;

      .icon-arrow-down {
        width: 16px;
        min-width: 16px;
        height: 16px;
        background: var(--color-semantic-content-normal-tertiary);

        &_rotate {
          transform: rotate(180deg);
        }
      }
    }

    &__info {
      cursor: pointer;
      flex: 1 1 100%;
      overflow: hidden;

      > h5 {
        @include body-bold;
        color: var(--color-semantic-content-normal-primary);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
      }

      > p {
        @include caption;
        color: var(--color-semantic-content-normal-tertiary);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
      }
    }

    &:hover,
    &:active,
    &_active {
      background: var(--color-semantic-background-hover-primary);
    }
  }

  .list {
    position: absolute;
    width: calc(100% - 32px);
    padding: 8px 0px;
    top: calc(100% + 6px);
    left: 16px;
    border-radius: 8px;
    border: 1px solid var(--color-semantic-border-normal-soft);
    background: var(--color-semantic-background-normal-primary);
    z-index: 12;

    &__item {
      display: flex;
      align-items: center;
      text-decoration: none;
      cursor: pointer;
      @include body-2;
      gap: 12px;
      padding: 0 12px;
      color: var(--color-semantic-content-normal-primary);

      img {
        width: 40px;
        height: 40px;
      }

      &:hover {
        background: var(--color-semantic-background-hover-primary);
      }
    }
  }

  &_collapsed {
    padding: 8px;
  }
}

@media (max-width: 1535px) {
  .main-sidebar-header {
    padding: 8px;

    .button {
      padding: 8px 12px 8px 8px;
    }

    .list {
      left: 8px;
      width: calc(100% - 16px);
    }
  }
}
</style>
